.startable-group:not(:last-child) {
  margin-bottom: 3rem;
}

.startable-group__title {
  padding-bottom: 1rem;
  font-size: 1.125rem;
}

.startable-group__startables {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  grid-gap: 1.5rem;
}
