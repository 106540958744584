.task-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content;
  grid-template-areas:
    'title'
    'header'
    'tasks';

  background-color: var(--task-list-background-color);
  color: var(--task-list-text-color, var(--text-color));
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.task-list__title {
  grid-area: title;
  padding: 1rem 1.5rem;
  line-height: inherit;
  font-size: 1.125rem;
  white-space: nowrap;

  color: var(--task-list-title-text-color, var(--text-color));
}

.task-list__tasks {
  grid-area: tasks;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit);
}

.box__subtitle--task-list {
  grid-area: header;

  background-color: var(--task-list-header-background-color, var(--background-color));

  padding: 1rem 1.5rem;
  font-size: 0.75rem;
  color: var(--task-list-header-text-color, var(--text-color));
}

.task-list-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'task-list-header-title task-list-header-task';
}

.task-list-header__title {
  grid-area: 'task-list-header-title';
}

.task-list-header__task {
  grid-area: 'task-list-header-task';
}

.task {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'task-title task-task';

  margin-bottom: 1rem;
}

.task__title {
  grid-area: 'task-title';
  margin-right: 0.5rem;
  line-height: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  -ms-overflow-style: none; /* Edge */
  scrollbar-width: none; /* Firefox */

  /* Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    text-overflow: initial;
    overflow: scroll;
  }
}

.task__task {
  grid-area: 'task-task';
  white-space: nowrap;
  overflow: scroll;

  -ms-overflow-style: none; /* Edge */
  scrollbar-width: none; /* Firefox */

  /* Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  .btn {
    display: block;
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 768px) {
  .task__task {
    .btn {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
}
