/* ********** */

/* ***** HACKS FOR 2.0 ALPHA VERSION BELOW ***** */

/* ********** */

.process-model {
  background: linear-gradient(36.64deg, #ffdc40 0%, #ff8356 100%);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.btn--start-button {
  background-color: transparent;
  border-color: #fff;
  color: #fff;

  margin: 5px;
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn--start-button:disabled {
  opacity: 0.6;
  transition: none;
}

.btn--start-button.active,
.btn--start-button:hover:not(:disabled) {
  background-color: #fff;
  color: var(--text-color);
}

.btn--task-continue {
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.startable {
  color: #fff;

  &:nth-child(1n) {
    background: linear-gradient(36.64deg, #0072ff 0%, #0d839e 100%);
  }

  &:nth-child(2n) {
    background: linear-gradient(45.92deg, #12e7d9 0%, #5b78e8 100%);
  }

  &:nth-child(3n) {
    background: linear-gradient(48.52deg, #3ae591 0%, #3bb3b5 100%);
  }

  &:nth-child(4n) {
    background: linear-gradient(36.64deg, #0b822b 0%, #15b713 100%);
  }

  &:nth-child(5n) {
    background: linear-gradient(36.64deg, #f9cc06 0%, #ff8356 100%);
  }

  &:nth-child(6n) {
    background: linear-gradient(36.64deg, #e0992f 0%, #f76240 100%);
  }

  &:nth-child(7n) {
    background: linear-gradient(54.07deg, #f54b9f 0%, #fe7374 100%);
  }

  &:nth-child(8n) {
    background: linear-gradient(36.64deg, #f94e3e 0%, #db375a 100%);
  }

  &:nth-child(9n) {
    background: linear-gradient(36.64deg, #f249c2 0%, #c837db 100%);
  }

  &:nth-child(10n) {
    background: linear-gradient(36.64deg, #ba68c8 0%, #9c27b0 100%);
  }

  &:nth-child(11n) {
    background: linear-gradient(36.64deg, #9575cd 0%, #673ab7 100%);
  }

  &:nth-child(12n) {
    background: linear-gradient(36.64deg, #7986cb 0%, #3f51b5 100%);
  }
}

.process-model__process-body {
  display: none;
}

user-interaction-component {
  .frame {
    padding: 1rem 1.5rem;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  }
}
