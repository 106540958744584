.start-dialog-view {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.start-dialog-view__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  padding-top: 2rem;
  padding-bottom: 1rem;
}

.start-dialog-view__iframe {
  height: 100%;
  min-height: 150px;
  border: none;
}
