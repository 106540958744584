.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.loading-spinner__sublabel {
  position: absolute;
  margin-top: 5rem;
}

.loading-spinner:before {
  box-sizing: border-box;
  content: "";
  height: 30px;
  width: 30px;
  border-width: 4px;
  border-style: solid;
  border-color: #2180c0 #ccc #ccc;
  border-radius: 100%;
  animation: rotation 0.7s infinite linear;
}

.loading-spinner__backdrop ~ .loading-spinner:before {
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: #fff;
  z-index: 30;
}

.loading-spinner__backdrop {
  background-color: rgb(107 114 128 / 0.75);
  opacity: 0.5;
  z-index: 30;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
