.user-settings-view__version {
  padding-left: 0.25rem;

  font-size: 0.75rem;
  opacity: 0.5;
}

.user-settings-view__language {
  width: 10rem;
}
